/* eslint-disable react-refresh/only-export-components */
import { Code, ConnectError, createPromiseClient } from "@connectrpc/connect";
import { createConnectTransport } from "@connectrpc/connect-web";
import {
	MutationCache,
	QueryCache,
	QueryClient,
	QueryClientProvider,
	useQuery,
} from "@tanstack/react-query";
import { ReactNode } from "react";
import { WalletService } from "schema/gen/es/chiikipoint/wallet/v1/wallet_connect";

export const createClient = (token: string) =>
	createPromiseClient(
		WalletService,
		createConnectTransport({
			baseUrl: "/",
			useBinaryFormat: import.meta.env.PROD,
			interceptors: [
				(next) => async (req) => {
					req.header.set("Authorization", `Bearer ${token}`);
					return next(req);
				},
			],
		})
	);

const retry = (failureCount: number, err: unknown) => {
	if (err instanceof ConnectError) {
		if (err.code == Code.PermissionDenied || err.code == Code.Unauthenticated) {
			return false;
		}
	}
	return failureCount < 3;
};

const onError = (err: unknown) => {
	console.error(err);
};

const queryClient = new QueryClient({
	queryCache: new QueryCache({ onError }),
	mutationCache: new MutationCache({ onError }),
	defaultOptions: {
		queries: { retry, staleTime: 60 * 1000 },
		mutations: { retry: false },
	},
});

export const ConnectQueryClientProvider = ({
	children,
}: {
	children: ReactNode;
}) => (
	<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);

export function useQueryGetMe(token: string) {
	return useQuery({
		queryKey: ["checkCapability"],
		queryFn: () => createClient(token).getMe({}),
	});
}

export function useQueryGetTransactions(token: string) {
	return useQuery({
		queryKey: ["getTransactions"],
		queryFn: () => createClient(token).getTransactions({}),
	});
}

export function useQueryGetStores(token: string) {
	return useQuery({
		queryKey: ["getStores"],
		queryFn: () => createClient(token).getStores({}),
	});
}
