import { format } from "date-fns";
import { useOutletContext, useParams } from "react-router-dom";
import { Transaction } from "schema/gen/es/chiikipoint/wallet/v1/types_pb";
import GlobalHeader from "../components/GlobalHeader";
import { OutletContext } from "../components/Layout";
import { Loading } from "../components/Loading";
import { useQueryGetTenant } from "../hooks/account-connect";
import { useQueryGetTransactions } from "../hooks/wallet-connect";
import styles from "./transaction-detail.module.scss";
import ErrorMessage from "../components/error-message";

function PaymentTransaction({
	tenantName,
	tx,
	isRefund,
}: {
	tenantName: string;
	tx: Transaction;
	isRefund: boolean;
}) {
	// for type guard
	if (tx.detail.case !== "payment") {
		return;
	}

	return (
		<>
			{isRefund && <div className={styles.canceledHeader}>キャンセル済み</div>}

			<div className={styles.container}>
				<div className={styles.upperContainer}>
					<div className={styles.storeImage}>
						<img src={tx.detail.value.store?.company?.imageUrl} />
					</div>
					<div className={styles.storeTitle}>
						<div className={styles.storeName}>
							{tx.detail.value.store?.name}にお支払い
						</div>
						<div className={styles.date}>
							{format(tx.createdAt!.toDate(), "yyyy/MM/dd")}
						</div>
					</div>
				</div>

				<div className={styles.detailContainer}>
					<div className={styles.listItem}>
						<div className={styles.listInner}>
							<div className={styles.listLabel}>ポイント名</div>
							<div className={styles.listValue}>{tenantName}</div>
						</div>
					</div>

					<div className={styles.listItem}>
						<div className={styles.listInner}>
							<div className={styles.listLabel}>事業者名</div>
							<div className={styles.listValue}>
								{tx.detail.value?.store?.company?.name}
							</div>
						</div>
					</div>

					<div className={styles.listItem}>
						<div className={styles.listInner}>
							<div className={styles.listLabel}>店舗名</div>
							<div className={styles.listValue}>
								{tx.detail.value?.store?.name}
							</div>
						</div>
					</div>

					<div className={styles.listItem}>
						<div className={styles.listInner}>
							<div className={styles.listLabel}>支払いポイント</div>
							<div className={styles.listValue}>
								{Math.abs(Number(tx.amount)).toLocaleString("ja-JP")}pt
							</div>
						</div>
					</div>

					<div className={styles.listItem}>
						<div className={styles.listInner}>
							<div className={styles.listLabel}>決済日時</div>
							<div className={styles.listValue}>
								{format(tx.createdAt!.toDate(), "yyyy/MM/dd HH:mm:ss")}
							</div>
						</div>
					</div>

					<div className={styles.listItem}>
						<div className={styles.listInner} style={{ display: "block" }}>
							<div
								className={styles.listLabel}
								style={{
									textAlign: "left",
								}}
							>
								決済ID
							</div>
							<div className={styles.listValue} style={{ marginTop: ".6rem" }}>
								{tx.id}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

function GrantTransaction({
	tenantName,
	tx,
}: {
	tenantName: string;
	tx: Transaction;
}) {
	// for type guard
	if (tx.detail.case !== "grant") {
		return;
	}

	return (
		<>
			<div className={styles.container}>
				<div className={styles.upperContainer}>
					<div className={styles.storeTitle}>
						<div className={styles.storeName}>{tx.detail.value.reason}</div>
					</div>
				</div>

				<div className={styles.detailContainer}>
					<div className={styles.listItem}>
						<div className={styles.listInner}>
							<div className={styles.listLabel}>ポイント名</div>
							<div className={styles.listValue}>{tenantName}</div>
						</div>
					</div>

					<div className={styles.listItem}>
						<div className={styles.listInner}>
							<div className={styles.listLabel}>付与ポイント</div>
							<div className={styles.listValue}>
								{Math.abs(Number(tx.amount)).toLocaleString("ja-JP")}pt
							</div>
						</div>
					</div>

					<div className={styles.listItem}>
						<div className={styles.listInner}>
							<div className={styles.listLabel}>付与日時</div>
							<div className={styles.listValue}>
								{format(tx.createdAt!.toDate(), "yyyy/MM/dd HH:mm:ss")}
							</div>
						</div>
					</div>

					<div className={styles.listItem}>
						<div className={styles.listInner} style={{ display: "block" }}>
							<div
								className={styles.listLabel}
								style={{
									textAlign: "left",
								}}
							>
								付与ID
							</div>
							<div className={styles.listValue} style={{ marginTop: ".6rem" }}>
								{tx.id}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default function Transactions() {
	const { token } = useOutletContext<OutletContext>();
	const { data, isFetching, isSuccess, error } = useQueryGetTransactions(token);
	const { data: tenant } = useQueryGetTenant();
	const { tx_id } = useParams();

	if (isFetching) {
		return <Loading />;
	}

	if (!isSuccess) {
		return <ErrorMessage error={error} />;
	}

	const { transactions } = data;
	const tx = transactions.find((tx) => tx.id === tx_id);

	if (!tx) {
		throw new Error("tx is undefined");
	}

	const isRefund = transactions.some(
		(transaction) =>
			transaction.detail.case === "refund" &&
			transaction.detail.value?.transactionId === tx.id
	);

	return (
		<>
			<GlobalHeader title="取引履歴" backPath="/transactions" />
			{tx && (
				<PaymentTransaction
					tenantName={tenant?.name || ""}
					tx={tx}
					isRefund={isRefund}
				/>
			)}
			{tx && <GrantTransaction tenantName={tenant?.name || ""} tx={tx} />}
		</>
	);
}
