import { Wrapper } from "@googlemaps/react-wrapper";
import React, { useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { OutletContext } from "../components/Layout";
import { useQueryGetStores } from "../hooks/wallet-connect";
import { Loading } from "../components/Loading";
import ErrorMessage from "../components/error-message";

const Marker = (options: google.maps.MarkerOptions) => {
	const [marker, setMarker] = React.useState<google.maps.Marker>();

	React.useEffect(() => {
		if (!marker) {
			setMarker(new google.maps.Marker());
		}

		return () => {
			if (marker) {
				marker.setMap(null);
			}
		};
	}, [marker]);

	React.useEffect(() => {
		if (marker) {
			marker.setOptions(options);
		}
	}, [marker, options]);

	return null;
};

interface MapProps {
	children?:
		| React.ReactElement<google.maps.MarkerOptions>[]
		| React.ReactElement<google.maps.MarkerOptions>;
}

const Map = ({ children }: MapProps) => {
	const ref = useRef<HTMLDivElement>(null);
	const [map, setMap] = useState<google.maps.Map>();
	useEffect(() => {
		const option = {
			center: {
				lat: 38.4377808,
				lng: 141.4277946,
			},
			zoom: 15,
		};
		if (ref.current) {
			setMap(new window.google.maps.Map(ref.current, option));
		}
	}, [ref]);

	return (
		<>
			<div
				ref={ref}
				style={{
					height: "100%",
					width: "100%",
				}}
			/>
			{React.Children.map(children, (child) => {
				if (React.isValidElement(child)) {
					return React.cloneElement(child, { map });
				}
			})}
		</>
	);
};

const StoreMap = () => {
	const { token } = useOutletContext<OutletContext>();
	const apiKey = import.meta.env.VITE_GOOGLE_MAP_API_KEY;
	const { data, status, fetchStatus, error } = useQueryGetStores(token);

	const render = () => {
		return <></>;
	};

	if (status === "error") {
		return <ErrorMessage error={error} />;
	}

	if (!data) {
		return <Loading fetchStatus={fetchStatus} />;
	}

	return (
		<Wrapper apiKey={apiKey} render={render}>
			<Map>
				{data.stores.map((store, index) => (
					<Marker
						key={index}
						position={{
							lat: store.location!.latitude,
							lng: store.location!.longitude,
						}}
					/>
				))}
			</Map>
		</Wrapper>
	);
};

export default StoreMap;
