import { getDistance } from "geolib";
import { useEffect, useMemo, useState } from "react";
import { BiMap } from "react-icons/bi";
import { useOutletContext } from "react-router-dom";
import { Store } from "schema/gen/es/chiikipoint/wallet/v1/types_pb";
import GlobalHeader from "../components/GlobalHeader";
import { OutletContext } from "../components/Layout";
import { useQueryGetStores } from "../hooks/wallet-connect";
import { getGeolocation } from "../utils/sdk";
import styles from "./store-list.module.scss";
import { Loading } from "../components/Loading";
import ErrorMessage from "../components/error-message";

type StoreWithDistance = Store & { distance?: number };

export default function StoreList() {
	const { token } = useOutletContext<OutletContext>();
	const { data, status, fetchStatus, error } = useQueryGetStores(token);
	const [loadingDistance, setLoadingDistance] = useState(true);
	const [currentLocation, setCurrentLocation] = useState<{
		latitude: number;
		longitude: number;
	} | null>(null);

	useEffect(() => {
		getGeolocation()
			.then((location) => {
				if (location) {
					setCurrentLocation({
						latitude: location.latitude,
						longitude: location.longitude,
					});
				}
			})
			.catch((e) => {
				console.error(e);
			})
			.finally(() => {
				setLoadingDistance(false);
			});
	}, []);

	const stores: StoreWithDistance[] = useMemo(() => {
		if (!data) {
			return [];
		}

		if (!currentLocation) {
			return data.stores;
		}

		const sotresWithLocation = data.stores
			.filter((store) => store.location)
			.map((store) => {
				const distance = getDistance(
					{
						latitude: store.location!.latitude,
						longitude: store.location!.longitude,
					},
					{ ...currentLocation }
				);

				return { ...store, distance };
			})
			.sort((a, b) => a.distance - b.distance) as StoreWithDistance[];

		const storesWithoutLocation = data.stores.filter(
			(store) => !store.location
		);

		return [...sotresWithLocation, ...storesWithoutLocation];
	}, [data, currentLocation]);

	if (status === "error") {
		return <ErrorMessage error={error} />;
	}

	if (!data) {
		return <Loading fetchStatus={fetchStatus} />;
	}

	return (
		<>
			<GlobalHeader title="店舗リスト" />
			<div className={styles.list}>
				{stores.map((store) => (
					<div className={styles.listItem} key={store.slug}>
						<div className={styles.image}>
							<img src={store.company?.imageUrl} />
						</div>
						<div className={styles.content}>
							<div className={styles.companyName}>{store.company?.name}</div>
							<div className={styles.storeName}>{store.name}</div>
							<div className={styles.address}>
								<div className={styles.addressIcon}>
									<BiMap size={15} />
								</div>
								<div className={styles.addressContnt}>
									<a
										href={`https://www.google.com/maps/search/?api=1&query=${store.location?.latitude},${store.location?.longitude}`}
										target="_blank"
									>
										{store.address}
									</a>

									{loadingDistance ? (
										<div className={styles.loadingDistance}></div>
									) : (
										store.distance && (
											<div className={styles.distance}>
												{store.distance.toLocaleString("ja-JP")}m
											</div>
										)
									)}
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</>
	);
}
