import { atom } from "jotai";

type BaseTime = {
	source: "server" | "client";
	date: Date;
};

export const baseTimeAtom = atom<BaseTime>({
	source: "client",
	date: new Date(),
});

export const updateBaseTimeAtom = atom(null, async (_, set) => {
	try {
		const d = await fetch(window.location.origin, {
			method: "HEAD",
			cache: "no-cache",
		});
		const serverDateHeader = d.headers.get("Date");

		if (serverDateHeader) {
			set(baseTimeAtom, {
				source: "server",
				date: new Date(serverDateHeader),
			});
		} else {
			set(baseTimeAtom, {
				source: "client",
				date: new Date(),
			});
		}
	} catch (e: unknown) {
		if (e instanceof Error) {
			return {
				source: "client",
				date: new Date(),
			};
		}

		throw e;
	}
});
