import { ConnectError } from "@connectrpc/connect";
import { closeWindow } from "../utils/sdk";
import GlobalHeader from "./GlobalHeader";
import Notification from "./Notification";
import styles from "./error-message.module.scss";
import { extractConnectErrorMessage } from "../utils/connect";
import { captureException } from "@sentry/react";
import { ReactNode, useEffect } from "react";

export type Props = {
	error?: unknown;
	type?: "disagreement" | "network_error";
	message?: ReactNode;
};

export default function ErrorMessage({ type, error, message }: Props) {
	useEffect(() => {
		if (error instanceof ConnectError) {
			return;
		}

		// ConnectError以外のErrorはSentryに送信する
		if (error instanceof Error) {
			captureException(error);
		}
	}, [error]);

	if (type === "disagreement") {
		return (
			<>
				<GlobalHeader title="" />
				<Notification />
				<div className={styles.errorPage}>
					<div>
						地域ポイントアプリのご利用には、
						<br />
						権限付与の同意が必要です。
					</div>

					<button onClick={() => window.location.reload()}>再読み込み</button>
				</div>
			</>
		);
	}

	if (type === "network_error") {
		return (
			<>
				<GlobalHeader title="" />
				<Notification />
				<div className={styles.errorPage}>
					<div>通信環境を確認してください</div>

					<button onClick={() => window.location.reload()}>再読み込み</button>
				</div>
			</>
		);
	}

	if (error instanceof ConnectError) {
		return (
			<>
				<GlobalHeader title="" />
				<Notification />
				<div className={styles.errorPage}>
					<div>エラーが発生しました</div>

					<button onClick={() => closeWindow}>アプリを閉じる</button>

					<div className={styles.rawErrorMessage}>
						<div>ConnectError: {error.code}</div>
						<hr />
						<div>{extractConnectErrorMessage(error)}</div>
					</div>
				</div>
			</>
		);
	}

	return (
		<>
			<GlobalHeader title="" />
			<Notification />
			<div className={styles.errorPage}>
				<div>エラーが発生しました</div>
				<button onClick={() => closeWindow}>アプリを閉じる</button>

				<div className={styles.rawErrorMessage}>
					{message && <div>{message}</div>}
				</div>
			</div>
		</>
	);
}
