import { createBrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import React from "react";
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from "react-router-dom";
import getTenantName from "./env";

function getSentryEnv() {
	const tenantName = getTenantName();
	const appEnv = import.meta.env.VITE_APP_ENV;
	return `${tenantName}-${appEnv}`;
}

export function initSentry() {
	if (import.meta.env.VITE_ENABLE_SENTRY == "true") {
		Sentry.init({
			dsn: "https://0755aad840795d7d737bfcf46d319dca@o4504292631707648.ingest.sentry.io/4505941762572288",
			integrations: [
				new Sentry.BrowserTracing({
					// See docs for support of different versions of variation of react router
					// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
					routingInstrumentation: Sentry.reactRouterV6Instrumentation(
						React.useEffect,
						useLocation,
						useNavigationType,
						createRoutesFromChildren,
						matchRoutes
					),
				}),
				new Sentry.Replay(),
			],

			// パフォーマンスの計測は行わない
			tracesSampleRate: 0,
			// エラーのないセッションでのリプレイは不要なので無効化
			replaysSessionSampleRate: 0,
			// すべてのユーザーで replay を有効にする
			replaysOnErrorSampleRate: 1.0,

			environment: getSentryEnv(),
		});
	}
}

export const sentryCreateBrowserRouter =
	Sentry.wrapCreateBrowserRouter(createBrowserRouter);
