import { ReactNode } from "react";
import styles from "./global-banner.module.scss";
import { IoMdInformationCircleOutline } from "react-icons/io";
import getTenantName from "../utils/env";

type GlobalBanner = {
	tenant: string | null;
	startAt: Date;
	endAt: Date;
	message: ReactNode;
};

const banners: GlobalBanner[] = [
	{
		tenant: "tome",
		startAt: new Date("2024-01-11 00:00:00"),
		endAt: new Date("2024-01-16 00:00:00"),
		message: (
			<div>
				登米市地域ポイントの利用期間は令和６年１月１５日まで！ご利用忘れの無いようにご注意ください！
			</div>
		),
	},
];

export default function GlobalBanner() {
	const tenant = getTenantName();
	const banner = banners.find((banner) => {
		const now = new Date();
		const isOnTime = now >= banner.startAt && now <= banner.endAt;
		const isTargetTenant =
			banner.tenant === null ? true : banner.tenant === tenant;
		return isOnTime && isTargetTenant;
	});

	if (!banner) {
		return null;
	}

	return (
		<div className={styles.banner}>
			<div className={styles.icon}>
				<IoMdInformationCircleOutline />
			</div>
			<div>{banner.message}</div>
		</div>
	);
}
