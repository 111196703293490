import { format } from "date-fns";
import { useEffect, useMemo, useRef } from "react";
import { BsCheckCircle } from "react-icons/bs";
import { useReward } from "react-rewards";
import {
	useLocation,
	useNavigate,
	useOutletContext,
	useParams,
} from "react-router-dom";
import { Transaction } from "schema/gen/es/chiikipoint/wallet/v1/types_pb";
import kessai from "../assets/kessai-kanryou.mp3";
import GlobalHeader from "../components/GlobalHeader";
import { OutletContext } from "../components/Layout";
import { useQueryGetMe, useQueryGetStores } from "../hooks/wallet-connect";
import styles from "./complete.module.scss";
import ErrorMessage from "../components/error-message";
import { captureException } from "@sentry/react";

export default function Complete() {
	const { token } = useOutletContext<OutletContext>();
	const navigate = useNavigate();
	const { state } = useLocation();
	const ref = useRef<HTMLAudioElement | null>(null);
	const { reward } = useReward("rewardId", "confetti", {
		lifetime: 1000,
		elementSize: 10,
	});
	const {
		data: userData,
		status: userStatus,
		error: getMeError,
		refetch,
	} = useQueryGetMe(token);
	const {
		data: storeData,
		status: storesStatus,
		error: getStoresError,
	} = useQueryGetStores(token);
	const { qr_tag_id } = useParams();
	const transaction = state.transaction as Transaction;

	const store = useMemo(() => {
		if (!storeData) {
			return undefined;
		}
		return storeData.stores.find((s) => s.slug === qr_tag_id);
	}, [storeData, qr_tag_id]);

	const goHome = () => {
		navigate(`/`);
	};

	useEffect(() => {
		reward();
		refetch();

		if (ref && ref.current) {
			ref.current.play().catch((error) => {
				if (error instanceof Error) {
					if (error.name !== "NotAllowedError" && error.name !== "AbortError") {
						console.error(error);
						// NotAllowedError 以外のエラーの場合は Sentry に報告
						captureException(error);
					}
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// エラー
	if (userStatus === "error") {
		return <ErrorMessage error={getMeError} />;
	}
	if (storesStatus === "error") {
		return <ErrorMessage error={getStoresError} />;
	}

	const amount = Math.abs(Number(transaction.amount));

	return (
		<div className={styles.pageContainer}>
			<GlobalHeader title="" />

			<div className={styles.container}>
				<audio ref={ref} src={kessai} />
				<span id="rewardId" className={styles.reward} />

				<div className={styles.storeSideContainer}>
					<div className={styles.storeInfo}>
						<div className={styles.storeIcon}>
							<img src={store?.company?.imageUrl} />
						</div>
						<div className={styles.storeNameContainer}>
							<div className={styles.tenantName}>{store?.name}</div>
							<div className={styles.storeName}>{store?.company?.name}</div>
						</div>
					</div>

					<div className={styles.storePointContainer}>
						<div className={styles.storePoint}>
							<div className={styles.storePointValue}>
								{amount.toLocaleString("ja-JP")}
							</div>
							<div className={styles.storePointUnit}>pt</div>
						</div>
						<div className={styles.storeTime}>
							{format(new Date(), "yyyy/MM/dd HH:mm:ss")}
						</div>
					</div>

					<div className={styles.storeTxIdContainer}>
						<div className={styles.storeTxIdLabel}>決済ID</div>
						<div className={styles.storeTxIdValue}>{transaction.id}</div>
					</div>
				</div>

				<div className={styles.headerLabel}>
					<div className={styles.headerLabelValueLeft}>
						<BsCheckCircle />
						<div className={styles.headerLabelValueText}>支払い完了</div>
					</div>
					<div className={styles.headerLabelValueRight}>
						<BsCheckCircle />
						<div className={styles.headerLabelValueText}>支払い完了</div>
					</div>
				</div>

				<div className={styles.userSideContainer}>
					<div className={styles.userSideContainerInner}>
						<div>
							<div className={styles.storeInfo}>
								<div className={styles.storeIcon}>
									<img src={store?.company?.imageUrl} />
								</div>
								<div className={styles.storeNameContainer}>
									<div className={styles.tenantName}>{store?.name}</div>
									<div className={styles.storeName}>{store?.company?.name}</div>
								</div>
							</div>
							<div className={styles.inputContainer}>
								{userData && (
									<div className={styles.inputBalance}>
										ポイント残高{" "}
										{Number(userData.balance).toLocaleString("ja-JP")}pt
									</div>
								)}
								<div className={styles.inputValueContainer}>
									<div className={styles.inputPointContainer}>
										<div className={styles.inputValue}>
											{amount.toLocaleString("ja-JP")}
										</div>
										<div className={styles.inputUnit}>pt</div>
									</div>
								</div>
							</div>
							<div className={styles.buttonContainer}>
								<button onClick={goHome}>閉じる</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
