import {
	GeolocationResources,
	SDKInstance,
	SignedSourceResources,
	TicketSourceResources,
	createSDKInstance,
	getCurrentGeolocation,
	getSubscriptionIdWithSignatureV0,
	readResourceWithSignature,
	requestPermissionV0,
	openQrScanner as sdkOpenQrScanner,
	closeWindow as sdkCloseWindow,
	PushNotificationResourceId,
} from "@pocketsign/in-app-service-sdk";
import add from "date-fns/add";
import * as jose from "jose";
import { client } from "../hooks/account-connect";

const readResourceIds = [
	SignedSourceResources.birthDate,
	SignedSourceResources.cityAddress,
	SignedSourceResources.gender,
	TicketSourceResources.birthDate,
	TicketSourceResources.cityAddress,
	TicketSourceResources.gender,
];

const invokeResourceIds = [GeolocationResources.normalAccuracy];

let sdkInstance: SDKInstance | undefined;
export async function createSDK() {
	if (sdkInstance) {
		return sdkInstance;
	}

	const { serviceId } = await client.getTenant({});

	const backendMode =
		import.meta.env.VITE_PS_SDK_MODE ??
		(import.meta.env.MODE !== "production" ? "API" : "APP");

	sdkInstance = await createSDKInstance({
		serviceId,
		baseUrl:
			backendMode === "API" ? "https://internal.core.mock.p8n.dev" : undefined,
		accessToken:
			backendMode === "API"
				? import.meta.env.VITE_DEV_P8N_ACCESS_TOKEN
				: undefined,
	});
	return sdkInstance;
}

export async function requestPermission() {
	const sdk = await createSDK();
	const expireAt = add(new Date(), { years: 1 });

	return requestPermissionV0(sdk, [
		...readResourceIds.map((resourceId) => ({
			resourceId,
			verbs: [{ verb: "read" as const, expireAt }],
		})),
		...invokeResourceIds.map((resourceId) => ({
			resourceId,
			verbs: [{ verb: "invoke" as const, expireAt }],
		})),
		{
			resourceId: PushNotificationResourceId,
			verbs: [
				{
					verb: "invoke",
					term: "always",
					expireAt: new Date("2049-12-31T14:59:59Z"),
				},
			],
		},
	]);
}

export async function getResourcesWithSignature() {
	const sdk = await createSDK();

	const resourcesIdValue: { id: string; value: string }[] = [];

	for (const resourceId of readResourceIds) {
		const data = await readResourceWithSignature(sdk, {
			resourceId,
		});

		if (data.result !== "success") {
			throw new Error(`failed to get resource: ${data.errno}`);
		}

		if (data.value !== null) {
			resourcesIdValue.push({
				id: resourceId,
				value: data.value,
			});
		}
	}

	return resourcesIdValue;
}

function jwtIsExpired(jwt: string | null) {
	if (!jwt) return true;

	const payload = jose.decodeJwt(jwt);

	if (!payload.exp) {
		throw new Error("JWT does not have exp claim");
	}

	return payload.exp * 1000 < Date.now();
}

export async function getSubscriptionId() {
	const sdk = await createSDK();
	const data = await getSubscriptionIdWithSignatureV0(sdk);

	if (data.result !== "success") {
		throw new Error(`failed to get subscription id: ${data.errno}`);
	}

	return data.subscriptionId;
}

export async function getGeolocation({ maximumAge: _maximumAge = 0 } = {}) {
	const location = await getCurrentGeolocation(await createSDK(), {
		maximumAge: _maximumAge,
	});

	if (location.result != "success") return;

	return location.coords;
}

export async function openQrScanner() {
	const sdk = await createSDK();
	return sdkOpenQrScanner(sdk);
}

export async function closeWindow() {
	const sdk = await createSDK();
	return sdkCloseWindow(sdk);
}

if (import.meta.vitest) {
	const { describe, it, expect, vi } = import.meta.vitest;

	describe("jwtIsExpired", () => {
		const jwt =
			"eyJhbGciOiJFUzI1NiIsImtpZCI6ImRKSHY2Q1Uzc0czaWNYQ1FKRVJzTnBtc1Y3b1VpbUN6VTlQNVdCazVIY1EiLCJ0eXAiOiJKV1QifQ.eyJhdWQiOiIzNjEwNzlhYS0zYjBlLTQwNmQtYmM3OC1jMDJkNjVjNzJlNWUiLCJleHAiOjE2OTU2NTYxNTUsImlhdCI6MTY5NTY1NTg1NSwic3Vic2NyaXB0aW9uSUQiOiIzNjE0NGMwZi0xOGJjLTRiYjMtYmFhYS1lMWI2YTI4NzZkOWMiLCJzdWJzY3JpcHRpb25faWQiOiIzNjE0NGMwZi0xOGJjLTRiYjMtYmFhYS1lMWI2YTI4NzZkOWMifQ.O-8Oxy3kIfapqTP3FWPrTQab0VRcNi6HuHVkVj_d6cGs4XyIbljkpE3wZsXtjApSXN750TAikVw6AsYCHyUcvg";

		it("should return true if jwt is expired", () => {
			vi.setSystemTime(new Date("2023-10-01T00:00:00.000Z"));
			expect(jwtIsExpired(null)).toBe(true);
			expect(jwtIsExpired("")).toBe(true);
			expect(jwtIsExpired(jwt)).toBe(true);
		});

		it("should return false if jwt is not expired", () => {
			vi.setSystemTime(new Date("2023-01-01T00:00:00.000Z"));
			expect(jwtIsExpired(jwt)).toBe(false);
		});
	});
}
