import { createPromiseClient } from "@connectrpc/connect";
import { createConnectTransport } from "@connectrpc/connect-web";
import { useQuery } from "@tanstack/react-query";
import { AccountService } from "schema/gen/es/chiikipoint/wallet/v1/account_connect";

export const client = createPromiseClient(
	AccountService,
	createConnectTransport({
		baseUrl: "/",
		useBinaryFormat: import.meta.env.PROD,
	})
);

export function useQueryGetTenant() {
	return useQuery({
		queryKey: ["getTenant"],
		queryFn: () => client.getTenant({}),
	});
}
