import { Chiiki } from "./types";

export default function getTenantName(): Chiiki | "demo" {
	const { hostname } = location;

	const [tenantName, appName] = hostname.split(".");

	if (appName === "wallet") {
		return tenantName as Chiiki;
	} else {
		return "demo";
	}
}

if (import.meta.vitest) {
	if (import.meta.vitest) {
		const { describe, it, expect, vi } = import.meta.vitest;

		describe("getTenantName", () => {
			it("returns the tenant name", () => {
				vi.stubGlobal("location", {
					hostname: "localhost",
				});
				expect(getTenantName()).toBe("demo");

				vi.stubGlobal("location", {
					hostname: "127.0.0.1",
				});
				expect(getTenantName()).toBe("demo");

				vi.stubGlobal("location", {
					hostname: "demo.wallet.chiikipoint.mock.pocketsign.app",
				});
				expect(getTenantName()).toBe("demo");

				vi.stubGlobal("location", {
					hostname: "onagawa.wallet.chiikipoint.mock.pocketsign.app",
				});
				expect(getTenantName()).toBe("onagawa");
			});
		});
	}
}
