import { atom } from "jotai";

type Notification = {
	title: string;
	message?: string;
	type: "success" | "error";
	error?: unknown;
};

export const notificationAtom = atom<null | Notification>(null);

export const notificationAtomWithReset = atom(
	(get) => get(notificationAtom),
	(get, set, update: Notification) => {
		set(notificationAtom, update);
		setTimeout(() => {
			set(notificationAtom, null);
		}, 1000 * 10);
	}
);
