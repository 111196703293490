import Complete from "./pages/Complete";
import ConfirmPay from "./pages/ConfirmPay";
import Home from "./pages/Home";
import Pay from "./pages/Pay";
import StoreMap from "./pages/StoreMap";
import TransactionDetail from "./pages/TransactionDetail";
import Transactions from "./pages/Transactions";
import Layout from "./components/Layout";
import { sentryCreateBrowserRouter } from "./utils/sentry";
import StoreList from "./pages/StoreList";

export const router = sentryCreateBrowserRouter([
	{
		path: "/",
		element: <Layout />,
		children: [
			{
				index: true,
				element: <Home />,
			},
			{
				path: "/map",
				element: <StoreMap />,
			},
			{
				path: "/stores",
				element: <StoreList />,
			},
			{
				path: "/transactions",
				element: <Transactions />,
			},
			{
				path: "/transactions/:tx_id",
				element: <TransactionDetail />,
			},
			{
				path: "/pay",
				element: <Pay />,
			},
			{
				path: "/pay/confirm",
				element: <ConfirmPay />,
			},
			{
				path: "/pay/:qr_tag_id/complete",
				element: <Complete />,
			},
			{
				path: "/pay/:qr_tag_id",
				element: <Pay />,
			},
			{
				path: "/pay/:qr_tag_id/confirm",
				element: <ConfirmPay />,
			},
		],
	},
]);
