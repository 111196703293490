import { Code, ConnectError } from "@connectrpc/connect";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { tokenAtom } from "../atoms/auth";
import { notificationAtom } from "../atoms/notification";
import { client } from "../hooks/account-connect";
import { extractConnectErrorMessage } from "../utils/connect";
import {
	closeWindow,
	getResourcesWithSignature,
	getSubscriptionId,
	requestPermission,
} from "../utils/sdk";
import BottomNavigation from "./BottomNavigation";
import GlobalHeader from "./GlobalHeader";
import { Loading } from "./Loading";
import Notification from "./Notification";
import styles from "./layout.module.scss";
import ErrorMessage from "./error-message";
import ComingSoonOverlay from "./ComingSoonOverlay";
import { tenantStatusAtom } from "../atoms/tenant";
import { baseTimeAtom, updateBaseTimeAtom } from "../atoms/time";
import getTenantName from "../utils/env";

export type OutletContext = {
	token: string;
};

export default function Layout() {
	const [token, setToken] = useAtom(tokenAtom);
	const setNotification = useSetAtom(notificationAtom);
	const [loading, setLoading] = useState<boolean>(true);
	const location = useLocation();
	const [needAgree, setNeedAgree] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string | undefined>();
	const setTenantStatus = useSetAtom(tenantStatusAtom);
	const baseTime = useAtomValue(baseTimeAtom);
	const updateBaseTime = useSetAtom(updateBaseTimeAtom);

	useEffect(() => {
		updateBaseTime();
		// 起動時の時間を取得するために、初回のみ実行する
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const tenantName = getTenantName();

		// 2024年1月1日以降、女川町地域ポイント終了
		if (
			tenantName === "onagawa" &&
			baseTime.date > new Date("2024-01-01T00:00:00+09:00")
		) {
			setTenantStatus("closed");
			return;
		}

		// 2024年1月16日以降、登米市地域ポイント終了
		if (
			tenantName === "tome" &&
			baseTime.date > new Date("2024-01-16T00:00:00+09:00")
		) {
			setTenantStatus("closed");
			return;
		}

		// 2024年2月1日以降、残4地域ポイント終了
		if (
			(tenantName === "ishinomaki" ||
				tenantName === "higashimatsushima" ||
				tenantName === "minamisanriku" ||
				tenantName === "tooda") &&
			baseTime.date > new Date("2024-02-01T00:00:00+09:00")
		) {
			setTenantStatus("closed");
			return;
		}

		setTenantStatus("active");
	}, [baseTime, setTenantStatus]);

	const getImpersonateToken = useCallback(() => {
		const params = new URLSearchParams(window.location.hash.slice(1));
		const token = params.get("token");
		if (token) {
			return token;
		}
		return sessionStorage.getItem("impersonate-token");
	}, []);

	const initialize = useCallback(async () => {
		// 指定したトークンを使用する（Impersonate用）
		const token = getImpersonateToken();
		if (token) {
			sessionStorage.setItem("impersonate-token", token);
			window.location.hash = "";
			setToken(token);
			setLoading(false);
			return;
		}

		try {
			const response = await requestPermission();

			if (response.result !== "agree") {
				console.error(`failed to request permission: ${response.result}`);
				setNotification({
					type: "error",
					title: "認証に失敗しました",
				});
				setLoading(false);
				setNeedAgree(true);
				return;
			}

			const profiles = await getResourcesWithSignature();
			const externalToken = await getSubscriptionId();
			const data = await client.login({
				externalToken,
				profiles,
			});
			setToken(data.token);
		} catch (err: unknown) {
			console.error(err);
			if (err instanceof ConnectError) {
				if (err.code == Code.PermissionDenied) {
					setToken(null);
					setLoading(false);
					setErrorMessage(extractConnectErrorMessage(err));
					return;
				}
			}
		} finally {
			setLoading(false);
		}
	}, []);

	const showBottomNavigation = useMemo(() => {
		if (location.pathname.match(/^\/pay\/.+\/complete$/)) {
			return true;
		}
		if (location.pathname.startsWith("/pay")) {
			return false;
		}
		return true;
	}, [location]);

	useEffect(() => {
		initialize();
	}, [initialize]);

	if (loading) {
		return (
			<>
				<GlobalHeader title="" />
				<Notification />
				<Loading />
			</>
		);
	}

	// 対象地域外の場合はこの errorMessage を表示する
	if (errorMessage) {
		return (
			<>
				<GlobalHeader title="" />
				<Notification />
				<div className={styles.errorPage}>
					<div>{errorMessage}</div>

					<button onClick={() => closeWindow()}>アプリを閉じる</button>
				</div>
			</>
		);
	}

	if (needAgree) {
		return <ErrorMessage type="disagreement" />;
	}

	if (!token) {
		return <ErrorMessage type="network_error" />;
	}

	return (
		<>
			<ComingSoonOverlay />
			<Notification />
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					height: "100vh",
					width: "100vw",
					overflow: "hidden",
				}}
			>
				<div
					style={{
						flex: "1",
						maxHeight: "calc(100vh - 11rem)",
					}}
				>
					<Outlet context={{ token } satisfies OutletContext} />
				</div>
				{showBottomNavigation && <BottomNavigation />}
			</div>
		</>
	);
}
