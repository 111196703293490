import { LocalizedMessage } from "@buf/googleapis_googleapis.bufbuild_es/google/rpc/error_details_pb";
import { ConnectError } from "@connectrpc/connect";

export function extractConnectErrorMessage(err: ConnectError) {
	const localized = err
		.findDetails(LocalizedMessage)
		.find((i) => i.locale === "ja-JP");

	if (!localized) {
		return err.message;
	}
	return localized.message;
}
