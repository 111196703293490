import { ButtonHTMLAttributes } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import styles from "./button.module.scss";

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode;
  loading?: boolean;
};

export default function Button(props: Props) {
  const { children, loading, ...rest } = props;

  return (
    <button
      style={loading ? { display: "flex", justifyContent: "center" } : {}}
      disabled={loading}
      {...rest}
    >
      {loading ? (
        <AiOutlineLoading3Quarters className={styles.spinner} />
      ) : (
        children
      )}
    </button>
  );
}
