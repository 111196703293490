import styles from "./closed-card.module.scss";
import { useQueryGetTenant } from "../hooks/account-connect";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import getTenantName from "../utils/env";
import { useEffect, useState } from "react";

export default function ClosedCard() {
	const { data: tenant } = useQueryGetTenant();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [caption, setCaption] = useState<null | string>(null);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [formsUrl, setFormsUrl] = useState<null | string>(null);

	useEffect(() => {
		// const tenantName = getTenantName();
		// if (tenantName === "demo") {
		// 	setCaption("＼抽選でかまぼこが当たる／");
		// 	setFormsUrl(
		// 		"https://p8n.jp/i/Wo7cDeCBRhWPLVJGvLHEJg?u=https%3A%2F%2Fforms.pocketsign.app%2Fapp%2Fforms%2F7ff893ad-7952-4af2-98ed-8ccc4afc1780"
		// 	);
		// }
	}, []);

	return (
		<div className={styles.card}>
			<div className={styles.icon}>
				<img src={tenant?.imageUrl} />
			</div>
			<h1 className={styles.title}>
				{tenant?.name}のご利用ありがとうございました
			</h1>

			<div className={styles.body}>
				{tenant?.name}導入検討事業の実施期間は終了しました。
				<br />
				今後、皆さまのご意見を参考にサービスを改善してまいります。
			</div>

			<div className={styles.buttonContainer}>
				{caption && <div className={styles.buttonCaption}>{caption}</div>}
				{formsUrl && (
					<a href={formsUrl} target="_blank" className={styles.button}>
						アンケートに回答する
					</a>
				)}
			</div>
		</div>
	);
}
