import styles from "./coming-soon-overlay.module.scss";
import { closeWindow } from "../utils/sdk";
import { ReactNode, useCallback } from "react";
import getTenantName from "../utils/env";

const message = (
	<>
		<p className={styles.subject}>
			12月1日の正式版公開に向けて
			<br />
			ただいま準備中です。
		</p>

		<p className={styles.sub}>ご利用開始まで今しばらくお待ちください。</p>
	</>
);

type ComingSoon = {
	tenant: string | null;
	startAt: Date;
	endAt: Date;
	message: ReactNode;
};

const banners: ComingSoon[] = [
	{
		tenant: "ishinomaki",
		startAt: new Date("2023-11-16 00:00:00"),
		endAt: new Date("2023-12-01 00:00:00"),
		message,
	},
	{
		tenant: "higashimatsushima",
		startAt: new Date("2023-11-16 00:00:00"),
		endAt: new Date("2023-12-01 00:00:00"),
		message,
	},
	{
		tenant: "minamisanriku",
		startAt: new Date("2023-11-16 00:00:00"),
		endAt: new Date("2023-12-01 00:00:00"),
		message,
	},
];

export default function ComingSoonOverlay() {
	const tenant = getTenantName();

	const close = useCallback(async () => {
		await closeWindow();
	}, []);

	const banner = banners.find((banner) => {
		const now = new Date();
		const isOnTime = now >= banner.startAt && now <= banner.endAt;
		const isCurrentTenant = banner.tenant === null || banner.tenant === tenant;
		return isOnTime && isCurrentTenant;
	});

	if (!banner) {
		return null;
	}

	return (
		<div className={styles.container}>
			<div className={styles.message}>
				{banner.message}

				<button onClick={close}>アプリを閉じる</button>
			</div>
		</div>
	);
}
