import { Suspense, useCallback, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import GlobalHeader from "../components/GlobalHeader";
import { OutletContext } from "../components/Layout";
import { Loading } from "../components/Loading";
import { useQueryGetTenant } from "../hooks/account-connect";
import { useQueryGetMe } from "../hooks/wallet-connect";
import styles from "./home.module.scss";
import ErrorMessage from "../components/error-message";
import ClosedCard from "../components/ClosedCard";
import { useAtomValue } from "jotai";
import { tenantStatusAtom } from "../atoms/tenant";

function Card() {
	const { token } = useOutletContext<OutletContext>();
	const [iconImage, setIconImage] = useState("");
	const { data, status, error, fetchStatus, refetch } = useQueryGetMe(token);
	const { data: tenant } = useQueryGetTenant();

	// GIFが設定されている場合、タップした時にリプレイするため
	const onClickCard = useCallback(() => {
		setIconImage(`${tenant?.imageUrl}#${Date.now()}`);
	}, [tenant]);
	useEffect(() => {
		setIconImage(tenant?.imageUrl || "");
	}, [tenant]);

	useEffect(() => {
		refetch();
	}, [refetch]);

	if (status === "error") {
		return <ErrorMessage error={error} />;
	}

	if (!data) {
		return <Loading fetchStatus={fetchStatus} />;
	}

	return (
		<div className={styles.card} onClick={onClickCard}>
			<h1 className={styles.title}>{tenant?.name}</h1>
			<div className={styles.icon}>
				<img src={iconImage} />
			</div>
			<div className={styles.pointLabel}>ポイント残高</div>
			<div className={styles.point}>
				{data.balance.toLocaleString("ja-JP")}
				pt
			</div>
		</div>
	);
}

export default function Home() {
	const tenantStatus = useAtomValue(tenantStatusAtom);

	return (
		<>
			<GlobalHeader title="ホーム" />

			<div className={styles.container}>
				{tenantStatus !== "closed" && (
					<Suspense fallback={<Loading />}>
						<Card />
					</Suspense>
				)}

				{tenantStatus === "closed" && (
					<Suspense fallback={<Loading />}>
						<ClosedCard />
					</Suspense>
				)}
			</div>
		</>
	);
}
