import { FetchStatus } from "@tanstack/react-query";
import styles from "./loading.module.scss";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

type Props = {
	fetchStatus?: FetchStatus;
};

export function Loading(props: Props) {
	// Loading 画面を表示しているが、FetchStatus が idle である場合は、
	// 機内モード等で通信ができていない可能性があるため再読み込みボタンを表示する
	if (props.fetchStatus === "idle") {
		const onClickReload = () => window.location.reload();

		return (
			<div className={styles.container}>
				<div className={styles.idleContainer}>
					<div className={styles.idleMessage}>通信環境を確認してください</div>
					<button onClick={onClickReload} className={styles.button}>
						再読み込み
					</button>
				</div>
			</div>
		);
	}
	return (
		<div className={styles.container}>
			<div className={styles.loadingIcon}>
				<AiOutlineLoading3Quarters size={24} />
			</div>
		</div>
	);
}
