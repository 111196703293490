import { useNavigate, useLocation } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";
import { MdOutlineQrCodeScanner, MdOutlineArticle } from "react-icons/md";
import { BiMap } from "react-icons/bi";
import styles from "./bottom-navigation.module.scss";
import clsx from "clsx";
import { useCallback } from "react";
import { openQrScanner } from "../utils/sdk";
import { tenantStatusAtom } from "../atoms/tenant";
import { useAtomValue } from "jotai";

export default function BottmNavigation() {
	const tenantStatus = useAtomValue(tenantStatusAtom);
	const navigate = useNavigate();
	const location = useLocation();

	const isActive = useCallback(
		(path: string) => {
			return location.pathname.startsWith(path);
		},
		[location]
	);

	return (
		<div className={styles.container}>
			<button
				onClick={() => {
					navigate("/");
				}}
				className={clsx(
					styles.button,
					location.pathname === "/" && styles.active
				)}
			>
				<div className={styles.icon}>
					<AiOutlineHome size={30} />
				</div>
				<div className={styles.text}>ホーム</div>
			</button>
			{tenantStatus === "closed" ? (
				<button className={clsx(styles.button, styles.closed)}>
					<div className={styles.icon}>
						<MdOutlineQrCodeScanner size={30} />
					</div>
					<div className={styles.text}>読み取り</div>
				</button>
			) : (
				<button
					onClick={() => openQrScanner()}
					className={clsx(styles.button, isActive("/scan_qr") && styles.active)}
				>
					<div className={styles.icon}>
						<MdOutlineQrCodeScanner size={30} />
					</div>
					<div className={styles.text}>読み取り</div>
				</button>
			)}

			<button
				onClick={() => {
					navigate("/transactions");
				}}
				className={clsx(
					styles.button,
					isActive("/transactions") && styles.active
				)}
			>
				<div className={styles.icon}>
					<MdOutlineArticle size={30} />
				</div>
				<div className={styles.text}>取引履歴</div>
			</button>
			{tenantStatus === "closed" ? (
				<button className={clsx(styles.button, styles.closed)}>
					<div className={styles.icon}>
						<BiMap size={30} />
					</div>
					<div className={styles.text}>店舗リスト</div>
				</button>
			) : (
				<button
					onClick={() => {
						navigate("/stores");
					}}
					className={clsx(styles.button, isActive("/stores") && styles.active)}
				>
					<div className={styles.icon}>
						<BiMap size={30} />
					</div>
					<div className={styles.text}>店舗リスト</div>
				</button>
			)}
		</div>
	);
}
