import { MdClose } from "react-icons/md";
import { useCallback } from "react";
import styles from "./global-header.module.scss";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { closeWindow } from "../utils/sdk";
import GlobalBanner from "./GlobalBanner";

type Props = {
	title: string;
	backPath?: string;
	backState?: Record<string, unknown>;
	darkMode?: boolean;
};

export default function GlobalHeader({
	title,
	backPath,
	darkMode,
	backState,
}: Props) {
	const navigate = useNavigate();

	const close = useCallback(async () => {
		await closeWindow();
	}, []);

	const onClickBack = useCallback(() => {
		if (backPath) {
			navigate(backPath, { state: backState });
		}
	}, [backPath, backState, navigate]);

	return (
		<>
			<div className={clsx(styles.container, darkMode && styles.dark)}>
				<div className={styles.actionArea}>
					{backPath && (
						<button
							onClick={onClickBack}
							className={clsx(
								styles.actionArea,
								styles.backButton,
								darkMode && styles.dark
							)}
						>
							<IoMdArrowBack size={24} />
						</button>
					)}
				</div>
				<div className={styles.title}>{title}</div>
				<button
					className={clsx(
						styles.closeButton,
						styles.actionArea,
						darkMode && styles.dark
					)}
					onClick={close}
				>
					<MdClose size={24} />
				</button>
			</div>
			<GlobalBanner />
		</>
	);
}
