import { notificationAtom } from "../atoms/notification";
import { useAtomValue } from "jotai/react";
import styles from "./notification.module.scss";
import clsx from "clsx";
import { ConnectError } from "@connectrpc/connect";
import { extractConnectErrorMessage } from "../utils/connect";

export default function Notification() {
	const notification = useAtomValue(notificationAtom);

	if (!notification) {
		return <></>;
	}

	let connectCode = "";
	let connectMessage = "";
	if (notification.error && notification.error instanceof ConnectError) {
		connectCode = notification.error.code.toString();
		connectMessage = extractConnectErrorMessage(notification.error);
	}

	return (
		<div className={clsx(styles.container)}>
			<div
				className={clsx(
					styles.inner,
					notification.type === "error" && styles.error
				)}
			>
				<div className={styles.title}>{notification.title}</div>
				{notification.message && (
					<div className={styles.message}>{notification.message}</div>
				)}
				{connectCode && (
					<div className={styles.message}>ConnectError: {connectCode}</div>
				)}
				{connectMessage && (
					<div className={styles.message}>{connectMessage}</div>
				)}
			</div>
		</div>
	);
}
