/* eslint-disable @typescript-eslint/no-explicit-any */
import { format } from "date-fns";
import { MdChevronRight } from "react-icons/md";
import { Link, useOutletContext } from "react-router-dom";
import { Transaction } from "schema/gen/es/chiikipoint/wallet/v1/types_pb";
import GlobalHeader from "../components/GlobalHeader";
import { OutletContext } from "../components/Layout";
import { Loading } from "../components/Loading";
import { useQueryGetTransactions } from "../hooks/wallet-connect";
import styles from "./transactions.module.scss";
import { useEffect } from "react";
import ErrorMessage from "../components/error-message";

type DateTransactions = {
	[key: string]: Transaction[];
};

function PaymentTransactionItem({
	tx,
	isRefunded,
}: {
	tx: Transaction;
	isRefunded: boolean;
}) {
	// for type guard
	if (tx.detail.case !== "payment") {
		return null;
	}

	const amount = Math.abs(Number(tx.amount));

	return (
		<Link to={`/transactions/${tx.id}`} key={tx.id}>
			<div className={styles.txListItem}>
				<div className={styles.txListItemInner}>
					<div className={styles.txIcon}>
						<img src={tx.detail.value.store?.company?.imageUrl} />
					</div>
					<div>
						<div className={styles.storeName}>
							{tx.detail.value.store?.name}
							にお支払い
						</div>
						<div className={styles.txListDate}>
							{format(tx.createdAt!.toDate(), "yyyy/MM/dd HH:mm:ss")}
						</div>
					</div>

					<div className={styles.txListRight}>
						<div className={styles.txListPoint}>
							{isRefunded
								? "キャンセル済み"
								: `${amount.toLocaleString("ja-JP")}pt`}
						</div>

						<div className={styles.txListChevron}>
							<MdChevronRight size={26} />
						</div>
					</div>
				</div>
			</div>
		</Link>
	);
}

function GrantTransactionItem({ tx }: { tx: Transaction }) {
	// for type guard
	if (tx.detail.case !== "grant") {
		return null;
	}
	const amount = Math.abs(Number(tx.amount));

	return (
		<Link to={`/transactions/${tx.id}`} key={tx.id}>
			<div className={styles.txListItem}>
				<div className={styles.txListItemInner}>
					<div className={styles.txIcon} style={{ background: "none" }}></div>
					<div>
						<div className={styles.storeName}>{tx.detail.value.reason}</div>
						<div className={styles.txListDate}>
							{format(tx.createdAt!.toDate(), "yyyy/MM/dd HH:mm:ss")}
						</div>
					</div>

					<div className={styles.txListRight}>
						<div className={styles.txListPoint}>
							{`${amount.toLocaleString("ja-JP")}pt`}
						</div>

						<div className={styles.txListChevron}>
							<MdChevronRight size={26} />
						</div>
					</div>
				</div>
			</div>
		</Link>
	);
}

export default function Transactions() {
	const { token } = useOutletContext<OutletContext>();
	const { data, isSuccess, isFetching, refetch, error } =
		useQueryGetTransactions(token);

	useEffect(() => {
		refetch();
	}, []);

	if (isFetching) {
		return <Loading />;
	}

	if (!isSuccess) {
		return <ErrorMessage error={error} />;
	}

	const transactions = data.transactions.filter(
		(tx) => tx.detail.case === "payment" || tx.detail.case === "grant"
	);

	const refunds = data.transactions.filter((tx) => tx.detail.case === "refund");

	transactions.sort((a, b) => {
		const aId = Number(a.createdAt?.seconds);
		const bId = Number(b.createdAt?.seconds);

		if (aId < bId) {
			return 1;
		} else if (aId > bId) {
			return -1;
		} else {
			return 0;
		}
	});

	const dateTransactions: DateTransactions = {};

	for (const tx of transactions) {
		if (!tx.createdAt) continue;
		const key = format(tx.createdAt.toDate(), "yyyy年MM月");
		dateTransactions[key] = dateTransactions[key]
			? [...dateTransactions[key], tx]
			: [tx];
	}

	return (
		<>
			<GlobalHeader title="取引履歴" />
			<div className={styles.list}>
				{Object.entries(dateTransactions).map(([date, txs]) => {
					return (
						<div className={styles.txContainer} key={date}>
							<div className={styles.dateTitle}>{date}</div>
							<div className={styles.txListContainer}>
								{txs.map((tx) => {
									if (tx.detail.case === "payment") {
										const isRefunded = refunds.some(
											(refund) =>
												refund.detail.case === "refund" &&
												refund.detail.value.transactionId === tx.id
										);
										return (
											<PaymentTransactionItem
												tx={tx}
												isRefunded={isRefunded}
												key={tx.id}
											/>
										);
									}

									if (tx.detail.case === "grant") {
										return <GrantTransactionItem tx={tx} key={tx.id} />;
									}
								})}
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
}
