import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { ConnectQueryClientProvider } from "./hooks/wallet-connect.tsx";
import "./index.css";
import { router } from "./router.tsx";
import { initSentry } from "./utils/sentry.ts";

initSentry();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
	<React.StrictMode>
		<ConnectQueryClientProvider>
			<RouterProvider router={router} />
		</ConnectQueryClientProvider>
	</React.StrictMode>
);
