import clsx from "clsx";
import { format } from "date-fns";
import { useAtomValue, useSetAtom } from "jotai";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
	useLocation,
	useNavigate,
	useOutletContext,
	useParams,
} from "react-router-dom";
import { notificationAtomWithReset } from "../atoms/notification";
import Button from "../components/Button";
import GlobalHeader from "../components/GlobalHeader";
import { OutletContext } from "../components/Layout";
import {
	createClient,
	useQueryGetMe,
	useQueryGetStores,
} from "../hooks/wallet-connect";
import { formatNumber } from "../utils/utils";
import styles from "./confirm-pay.module.scss";
import { Loading } from "../components/Loading";
import ErrorMessage from "../components/error-message";
import { tenantStatusAtom } from "../atoms/tenant";

export default function ConfirmPay() {
	const { token } = useOutletContext<OutletContext>();
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [pending, setPending] = useState(true);
	const {
		data: userData,
		status: userStatus,
		fetchStatus: userFetchStatus,
		error: getMeError,
	} = useQueryGetMe(token);
	const {
		data: storeData,
		status: storesStatus,
		fetchStatus: storesFetchStatus,
		error: getStoresError,
	} = useQueryGetStores(token);
	const location = useLocation();
	const amount = location.state?.amount as undefined | number;
	const tag = location.state?.tag as undefined | null | string;
	const navigate = useNavigate();
	const { qr_tag_id } = useParams();
	const setNotification = useSetAtom(notificationAtomWithReset);
	const tenantStatus = useAtomValue(tenantStatusAtom);

	useEffect(() => {
		tenantStatus === "closed" && navigate("/");
	}, [navigate, tenantStatus]);

	const store = useMemo(() => {
		if (!storeData) {
			return undefined;
		}
		return storeData.stores.find((s) => s.slug === qr_tag_id);
	}, [storeData, qr_tag_id]);

	if (qr_tag_id === undefined) {
		throw new Error("qr_tag_id is undefined");
	}

	useEffect(() => {
		setTimeout(() => setOpen(true), 200);
	}, []);

	useEffect(() => {
		setTimeout(() => setPending(false), 1000);
	}, []);

	const pay = useCallback(async () => {
		if (loading) {
			return;
		}
		if (amount === undefined) {
			throw new Error("amount is undefined");
		}
		setLoading(true);

		try {
			const client = createClient(token);
			const { transaction } = await client.pay({
				amount: BigInt(amount),
				storeSlug: qr_tag_id,
				tags: tag ? [tag] : [],
			});

			if (!transaction) {
				throw new Error("transaction is undefined");
			}

			navigate(`/pay/${qr_tag_id}/complete`, {
				state: {
					transaction,
				},
				replace: true,
			});
		} catch (error) {
			setNotification({
				type: "error",
				title: "決済に失敗しました。",
				error,
			});
			throw error;
		} finally {
			setLoading(false);
		}
	}, [amount, loading, navigate, qr_tag_id, setNotification, tag, token]);

	// このページで一度通信を切って再読み込みするとこれになる
	if (typeof amount !== "number") {
		navigate(`/pay/${qr_tag_id}`);
		return <></>;
	}

	const payPath = `/pay/${qr_tag_id}`;

	// エラー
	if (userStatus === "error") {
		return <ErrorMessage error={getMeError} />;
	}
	if (storesStatus === "error") {
		return <ErrorMessage error={getStoresError} />;
	}

	// 読込中
	if (!userData) {
		return <Loading fetchStatus={userFetchStatus} />;
	}
	if (!storeData) {
		return <Loading fetchStatus={storesFetchStatus} />;
	}

	// 店舗一覧とユーザー情報は取得できたが、一致する店舗が存在しない
	if (!store) {
		return (
			<ErrorMessage
				message={
					<div style={{ display: "flex", gap: "6px", flexDirection: "column" }}>
						<div>データの取得に失敗しました</div>
						<div>店舗ID: {qr_tag_id}</div>
					</div>
				}
			/>
		);
	}

	return (
		<div className={styles.container}>
			<GlobalHeader
				title="支払いポイント確認"
				darkMode={true}
				backState={{ amount }}
				backPath={payPath}
			/>

			<div className={styles.mainContainer}>
				<div className={styles.storeSideContainer}>
					<div className={styles.storeInfo}>
						<div className={styles.storeIcon}>
							<img src={store.company?.imageUrl} />
						</div>
						<div className={styles.storeNameContainer}>
							<div className={styles.tenantName}>{store.name}</div>
							<div className={styles.storeName}>{store.company?.name}</div>
						</div>
					</div>

					<div className={styles.storePointContainer}>
						<div className={styles.storePoint}>
							<div className={styles.storePointValue}>
								{formatNumber(amount)}
							</div>
							<div className={styles.storePointUnit}>pt</div>
						</div>
						<div className={styles.storeTime}>
							{format(new Date(), "yyyy/MM/dd HH:mm:ss")}
						</div>
					</div>
				</div>

				<div className={styles.background}></div>

				<div className={clsx(styles.userSideContainer, open && styles.open)}>
					<div className={styles.storeInfo}>
						<div className={styles.storeIcon}>
							<img src={store.company?.imageUrl} />
						</div>
						<div className={styles.storeNameContainer}>
							<div className={styles.tenantName}>{store.name}</div>
							<div className={styles.storeName}>{store.company?.name}</div>
						</div>
					</div>
					<div className={styles.inputContainer}>
						{userData && (
							<div className={styles.inputBalance}>
								ポイント残高 {formatNumber(Number(userData.balance))}pt
							</div>
						)}
						<div className={styles.inputPointContainer}>
							<div className={styles.inputValue}>{formatNumber(amount)}</div>
							<div className={styles.inputUnit}>pt</div>
						</div>
					</div>
					<div className={styles.buttonContainer}>
						<Button
							className={clsx(styles.button, pending && styles.buttonPending)}
							onClick={pay}
							disabled={pending}
							loading={loading}
						>
							支払う
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}
