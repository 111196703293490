import clsx from "clsx";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
	useLocation,
	useNavigate,
	useOutletContext,
	useParams,
} from "react-router-dom";
import Button from "../components/Button";
import GlobalHeader from "../components/GlobalHeader";
import { OutletContext } from "../components/Layout";
import { useQueryGetMe, useQueryGetStores } from "../hooks/wallet-connect";
import { formatNumber } from "../utils/utils";
import styles from "./pay.module.scss";
import { Loading } from "../components/Loading";
import ErrorMessage from "../components/error-message";
import { tenantStatusAtom } from "../atoms/tenant";
import { useAtomValue } from "jotai";

export default function Pay() {
	const { token } = useOutletContext<OutletContext>();
	const location = useLocation();
	const defaultAmount = location.state?.amount;
	const navigate = useNavigate();
	const { qr_tag_id } = useParams();
	const [loading, setLoading] = useState(false);
	const [amount, setAmount] = useState<string>(
		defaultAmount ? Number(defaultAmount).toString() : ""
	);
	const [amountError, setAmountError] = useState("");
	const {
		data: userData,
		status: userStatus,
		fetchStatus: userFetchStatus,
		error: getMeError,
	} = useQueryGetMe(token);
	const {
		data: storeData,
		status: storesStatus,
		fetchStatus: storesFetchStatus,
		error: getStoresError,
	} = useQueryGetStores(token);
	const tenantStatus = useAtomValue(tenantStatusAtom);

	useEffect(() => {
		tenantStatus === "closed" && navigate("/");
	}, [navigate, tenantStatus]);

	const tag = useMemo(() => {
		const query = new URLSearchParams(location.search);
		return query.get("t");
	}, [location.search]);

	const store = useMemo(() => {
		if (!storeData) {
			return undefined;
		}
		return storeData.stores.find((s) => s.slug === qr_tag_id);
	}, [storeData, qr_tag_id]);

	const validateAmount = useCallback((): number | false => {
		const value = amount.replace(/[０-９]/g, (s) => {
			return String.fromCharCode(s.charCodeAt(0) - 65248);
		});

		const numberValue = Number(value);

		if (value === "") {
			setAmountError("金額を入力してください");
			return false;
		}
		if (value.match(/[^0-9]/)) {
			setAmountError("金額は数字で入力してください");
			return false;
		}
		if (value.length > 9) {
			setAmountError("金額が大きすぎます");
			return false;
		}
		if (numberValue <= 0) {
			setAmountError("金額が小さすぎます");
			return false;
		}
		if (Number(userData?.balance) < numberValue) {
			setAmountError("残高が足りません");
			return false;
		}
		if (store?.availableAmount === undefined) {
			setAmountError("店舗情報が取得できませんでした");
			return false;
		}
		if (
			// limitAmount: -1 は無制限、availableAmount は残り利用可能額
			Number(store.limitAmount) > -1 &&
			Number(store.availableAmount) < numberValue
		) {
			setAmountError("利用可能額を超えています");
			return false;
		}
		setAmountError("");

		return numberValue;
	}, [amount, userData, store]);

	const confirm = useCallback(() => {
		setLoading(true);
		const amount = validateAmount();
		if (!amount) {
			setLoading(false);
			return;
		}
		navigate(`/pay/${qr_tag_id}/confirm`, {
			state: {
				amount,
				tag,
			},
			replace: false,
		});
	}, [navigate, qr_tag_id, validateAmount, tag]);

	// エラー
	if (userStatus === "error") {
		return <ErrorMessage error={getMeError} />;
	}
	if (storesStatus === "error") {
		return <ErrorMessage error={getStoresError} />;
	}

	// 読込中
	if (!userData) {
		return <Loading fetchStatus={userFetchStatus} />;
	}
	if (!storeData) {
		return <Loading fetchStatus={storesFetchStatus} />;
	}

	// 店舗一覧とユーザー情報は取得できたが、一致する店舗が存在しない
	if (!store) {
		return (
			<ErrorMessage
				message={
					<div style={{ display: "flex", gap: "6px", flexDirection: "column" }}>
						<div>データの取得に失敗しました</div>
						<div>店舗ID: {qr_tag_id}</div>
					</div>
				}
			/>
		);
	}

	const limitAmount = Number(store.limitAmount);
	const availableAmount = Number(store.availableAmount);

	return (
		<div className={styles.pageContainer}>
			<GlobalHeader title="支払いポイント入力" backPath="/" />

			<div className={styles.container}>
				<div className={styles.storeInfo}>
					<div className={styles.storeIcon}>
						<img src={store.company?.imageUrl} />
					</div>
					<div className={styles.storeNameContainer}>
						<div className={styles.tenantName}>{store.name}</div>
						<div className={styles.storeName}>{store.company?.name}</div>
					</div>
				</div>
			</div>

			<div className={styles.inputContainer}>
				<div className={styles.inputBalance}>
					ポイント残高 {formatNumber(Number(userData.balance))}pt
				</div>

				<input
					value={amount}
					onChange={(e) => setAmount(e.target.value)}
					type="tel"
					autoFocus={true}
					className={styles.input}
					placeholder="0"
				/>

				{amountError && <div className={styles.inputError}>{amountError}</div>}
			</div>

			{limitAmount > -1 && (
				<div className={styles.limitAmount}>
					この店舗のご利用上限はお一人様{formatNumber(limitAmount)}ptです。
					{limitAmount !== availableAmount ? (
						<>
							<br />
							残り{formatNumber(availableAmount)}ptご利用可能です。
						</>
					) : null}
				</div>
			)}

			<div className={styles.buttonContainer}>
				<Button
					className={clsx(
						styles.button,
						amount.length < 1 && styles.buttonPending
					)}
					onClick={confirm}
					loading={loading}
					disabled={amount.length < 1}
				>
					次へ
				</Button>
			</div>
		</div>
	);
}
